module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-GE4PL3H8VV"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":650,"linkImagesToOriginal":false,"showCaptions":true,"backgroundColor":"none"}},"gatsby-remark-pre-content",{"resolve":"gatsby-remark-autolink-headers","options":{"icon":"<svg aria-hidden=\"true\" height=\"20\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"20\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>","className":"link-heading","maintainCase":false,"removeAccents":true,"elements":["h3","h4"],"id":"linked-heading"}},{"resolve":"gatsby-remark-vscode","options":{"theme":"Light+ (default light)","extensions":["systemverilog-1800-2012"]}}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"C:\\Users\\Rishav\\Documents\\website"},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-indicator/gatsby-browser.js'),
      options: {"plugins":[],"color":"linear-gradient(0deg, rgba(36,0,0,0) 0%, rgba(210,37,46,1) 50%)","height":"5px","paths":["/content/sv/**","/content/verilog/**"],"zIndex":"9999"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Octet Institute","short_name":"The OCT","lang":"en","start_url":"/","description":"This app makes learning VLSI easier by providing all the related topics in one place. The topics are crafted keeping in mind the needs of a person who is learning VLSI for the first time as well for a person who just wants to brush up the knowledge of verilog and other topics.","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"C:\\Users\\Rishav\\Documents\\website/src/images/web-logo.png","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"none","orientation":"portrait","include_favicon":false,"categories":["blog","education","electronics"],"shortcuts":[{"name":"Contents","url":"/content/","description":"Shows all the content available"},{"name":"Verilog","url":"/content/verilog/0-introduction/"}],"screenshots":[{"src":"/screenshots/ss-1.png","sizes":"1920x1080","type":"image/png"},{"src":"/screenshots/ss-2.png","sizes":"1920x1080","type":"image/png"},{"src":"/screenshots/ss-3.png","sizes":"1920x1080","type":"image/png"},{"src":"/screenshots/ss-4.png","sizes":"1920x1080","type":"image/png"},{"src":"/screenshots/ss-1-mob.jpg","sizes":"1440x3120","type":"image/jpg"},{"src":"/screenshots/ss-2-mob.jpg","sizes":"1440x3120","type":"image/jpg"},{"src":"/screenshots/ss-3-mob.jpg","sizes":"1440x3120","type":"image/jpg"},{"src":"/screenshots/ss-4-mob.jpg","sizes":"1440x3120","type":"image/jpg"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/icon*","**/static/wide_logo*"],"runtimeCaching":[{"urlPattern":{},"handler":"NetworkOnly"}]}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
