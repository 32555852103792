import pack_arr_1 from "../../../../src/sv/images/packed_array_simple.svg";
import pack_arr_md from "../../../../src/sv/images/packed_array_multi_dim.svg";
import pack_arr_md_adv from "../../../../src/sv/images/packed_array_multi_dim_adv.svg";
import unpack_arr from "../../../../src/sv/images/unpacked_array_simple.svg";
import unpack_arr_md from "../../../../src/sv/images/unpacked_array_multi_dim.svg";
import * as React from 'react';
export default {
  pack_arr_1,
  pack_arr_md,
  pack_arr_md_adv,
  unpack_arr,
  unpack_arr_md,
  React
};